// Generated by nuxt-prepare
export const network = {
  "logo": "",
  "title": "NextPark.gr",
  "domain": "nextpark.gr",
  "locale": "el",
  "locales": [
    "el"
  ],
  "country": "GR",
  "currency": "EUR",
  "timezone": "Europe/Warsaw",
  "format_date": "DD/MM/YYYY",
  "format_time": "HH:mm",
  "format_datetime": "HH:mm DD/MM/YYYY",
  "format_dayhours": 24,
  "format_firstdayofweek": "M",
  "direction": "LTR",
  "datepicker_mask": "DD/MM/YYYY",
  "datepicker_fdow": "2",
  "datepicker_clock": "24",
  "email": "contact@nextpark.gr",
  "phone": null,
  "phone_primary": null,
  "phone_secondary": "+302112345405",
  "phone_openhours": "8:00 - 16:00",
  "google_tag": "",
  "google_key": "",
  "google_captcha": "",
  "url_home": "https://nextpark.gr/el/",
  "url_rule": "https://nextpark.gr/{locale}/regulamin/"
}
export type Network = typeof network
